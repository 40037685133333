<template>
  <div class="wrap">
    <pageHead></pageHead>
    <div class="aboutCenter wrap">
      <el-image style="width: 100%; height: auto;" :src="require('@/assets/about.jpg')" fit="contain"></el-image>
      <div class="aboutCenterWrap">
        <div class="aboutCenterBox box">
          <div class="aboutCenterInfo aboutCenterChar">
            <el-image  class="aboutCenterInfoImg" style="width: 25%; height: auto;margin-bottom: 5%" :src="require('@/assets/aboutLogo.png')" fit="contain"></el-image>
            <div class="aboutCenterInfoCenter">
                <div class="aboutCenterInfoCenterTitle">安徽倾行网络科技有限公司</div>
                <div class="aboutCenterInfoCenterDescribe">{{about.describe}}</div>
            </div>
          </div>
          <div class="aboutDivision"></div>
          <div class="aboutDevelopment aboutCenterChar">
            <div class="aboutCenterTitle">
              发展历程
            </div>
            <div class="aboutDevelopmentCenter">
              <div class="aboutDevelopmentCenterTime" v-for="(item,index) in about.develop" :key="index">
                <img class="aboutDevelopmentCenterTimeLeft" :src="require('@/assets/aboutTime.png')" :style="{opacity: (typeof item)=='number'?1:0}" />
                <div>{{item}}{{(typeof item)=='number'?'年':''}}</div>
              </div>
            </div>
          </div>
          <div class="aboutDivision" v-if="dynamicList.length>0"></div>
          <div class="aboutDynamicBox" v-if="dynamicList.length>0">
            <div class="aboutCenterTitle" style="display: flex;flex:1;align-items: center;justify-content: space-between">
              <div>
                最新动态
              </div>
              <div style="font-size: 18px;color: #666666;cursor: pointer" @click="toDynamicList(false)">查看更多 <i class="el-icon-d-arrow-right"/></div>
            </div>
            <div class="aboutDynamicRowBox">
              <div v-for="(item,index) in dynamicList" :key="index" class="aboutDynamicRowWrap" >
                <div class="aboutDynamicRow" @click="toDynamicList(item.id)" >
                  <img :src="item.image" style="width: 100%;height: auto;" alt="">
                  <div class="aboutDynamicRowCenter">
                    <div>{{item.title}}</div>
                    <div>{{item.description}}</div>
                    <div>{{item.create_time}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="aboutCooperate aboutCenterChar">
            <div class="aboutCenterTitle">
              合作机构
            </div>
            <div class="aboutCooperateCenter">
              <el-image v-for="(item,index) in about.development" class="aboutCooperateCenterImage"  :key="index" style="width: 20%;margin: 0 2.5% 5%; height: auto;" :src="item" fit="contain"></el-image>
            </div>
          </div>
          <div class="aboutMap aboutCenterChar">
            <div class="aboutCenterTitle">
              联系我们
            </div>
            <baidu-map style="width: 100%;height: 500px"  :center="{lng: 117.14248, lat: 31.842501}" :zoom="18" class="map"  ak="vPoSV9A4T8kNjToZy6YfYdzDRgeR4Z8C">
              <!--比例尺控件-->
              <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
              <!--缩放控件-->
              <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-navigation>
              <bm-marker :position="{lng: 117.14248, lat: 31.842501}" :dragging="true">
<!--                <bm-label content="安徽倾行网络科技有限公司" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>-->
              </bm-marker>
              <!--聚合动态添加的点坐标-->
<!--              <bm-marker-clusterer :averageCenter="true">-->
<!--                <bm-marker v-for="marker of markers" :key="marker.code" :position="{lng: marker.lng, lat: marker.lat}" @click="lookDetail(marker)"></bm-marker>-->
<!--              </bm-marker-clusterer>-->
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
    <pageFoot></pageFoot>
  </div>
</template>

<script>
import pageHead from "@/views/assembly/pageHead/pageHead";
import pageFoot from "@/views/assembly/pageFoot/pageFoot";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
// import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
import {index} from "@/api"
// import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'
export default {
  name: "about",
  data(){
    return{
      about:this.$store.state.pageInfo.about,
      allInfo:this.$store.state.pageInfo.allPage,
      dynamicList:[]
    }
  },
  components:{
    pageHead,
    pageFoot,
    BaiduMap,
    BmScale,
    BmNavigation,
    BmMarker,
    // BmMarkerClusterer
  },
  mounted() {

    this.getList()
    // var map = new BMapGL.Map("container");          // 创建地图实例
    // var point = new BMapGL.Point(116.404, 39.915);  // 创建点坐标
    // map.centerAndZoom(point, 15);                 // 初始化地图，设置中心点坐标和地图级别
  },
  methods:{
    toDynamicList(e){
      if(e){
        this.$router.push({name:'dynamicList', query: {id: e}})
      }else{
        this.$router.push({name:'dynamicList'})
      }
    },
    getList(e){
      var page=1
      if(e){
        page=1
      }else{
        page=e
      }
      index({page:page,limit:3}).then(res=>{
        this.dynamicList=res.data.data.data
        console.log(this.dynamicList)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.aboutCenterWrap{
  width: 100%;
  background: #F7F7F7;
}
.aboutCenterBox{
  margin-top: -5%;
  position: relative;
  background: #ffffff;
  padding-top: 5%;
}
.aboutCenterChar{
  padding: 0% 8% 0;
}
.aboutCenterInfo{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aboutCenterInfoCenter{
  width: 75%;
  padding-left: 8%;
  box-sizing: border-box;
  margin-bottom: 5%;
  .aboutCenterInfoCenterTitle{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 2%;
  }
  .aboutCenterInfoCenterDescribe{
    font-size: 18px;
    line-height: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.aboutDivision{
  background: #F7F7F7;
  height: 2px;
  width: 90%;
  margin: 0 auto 1.5%;
}
.aboutCenterTitle{
  margin-bottom: 2%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.aboutDevelopmentCenterTime{
  display: flex;
  align-items:center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin-bottom: 2.5%;
  .aboutDevelopmentCenterTimeLeft{
    width: 2%;
    min-width: 18px;
    display: block;
    height: auto;
    min-height: 18px;
    margin-right: 2%;
  }
}
.aboutCooperate{
  margin-top: 6%;
}
.aboutMap{
  padding-bottom: 5%;
}
.aboutDynamicBox{
  width: 84%;
  margin: 0 auto;
}
.aboutDynamicRowBox{
  display: flex;
  justify-content: space-between;
}
.aboutDynamicRowWrap{
  width: 30%;
  box-shadow: 0px 2px 8px 0px rgba(128, 128, 128, 0.4);
}
.aboutDynamicRowCenter{
  padding:15px 18px;
}
.aboutDynamicRowCenter div:nth-child(1){
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.aboutDynamicRowCenter div:nth-child(2){
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 34px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

}
.aboutDynamicRowCenter div:nth-child(3){
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 34px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

}
@media screen and (max-width: 1000px) {
  .aboutDivision {
    width: 100%;
  }

  .aboutDynamicRowWrap:nth-child(3){
    display: none;
  }
  .aboutDynamicRowWrap{
    width: 48%;
  }
}
</style>